import axios from 'axios'
import store from '@/store'

let getOpeningHoursToken = null
let getOpeningHoursDayToken = null
let getOpeningHoursDateToken = null

export default {
  methods: {
    cancelGetOpeningHours() {
      if (getOpeningHoursToken) {
        getOpeningHoursToken.cancel()
      }
    },
    cancelGetOpeningHoursDay() {
      if (getOpeningHoursDayToken) {
        getOpeningHoursDayToken.cancel()
      }
    },
    cancelGetOpeningHoursDate() {
      if (getOpeningHoursDateToken) {
        getOpeningHoursDateToken.cancel()
      }
    },

    async getOpeningHours(
      date,
      channelId = 0,
      locationId = 0,
      meetingtypId = 0
    ) {

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/openinghour`,
        {
          // cancelToken: getOpeningHoursToken.token,

          params: {
            date: date,
            channelId: channelId,
            locationId: locationId,
            meetingtypeId: meetingtypId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getOpeningHoursDay(locationId = 0, meetingtypId = 0) {
      this.cancelGetOpeningHoursDay()
      getOpeningHoursDayToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/OpeningHour/location/${locationId}/day`,
        {
          cancelToken: getOpeningHoursDayToken.token,

          params: {
            meetingtypeId: meetingtypId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationOpeningHoursPeriod(
      locationId = 0,
      meetingtypId = 0,
      startDate,
      endDate
    ) {
      this.cancelGetOpeningHoursDay()
      getOpeningHoursDayToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/OpeningHour/location/${locationId}/period`,
        {
          cancelToken: getOpeningHoursDayToken.token,

          params: {
            meetingtypeId: meetingtypId,
            startDate: startDate,
            endDate: endDate
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getOpeningHoursDate(
      locationId = 0,
      meetingtypeId = 0,
      startDate = '',
      endDate = ''
    ) {
      this.cancelGetOpeningHoursDate()
      getOpeningHoursDateToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/OpeningHour/location/${locationId}/date`,
        {
          cancelToken: getOpeningHoursDateToken.token,

          params: {
            meetingtypeId: meetingtypeId,
            startDate: startDate,
            endDate: endDate
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveOpeninghoursDays(locationId, days) {
      let criteria = {
        Days: days
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/OpeningHour/location/${locationId}/days`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveOpeninghoursDates(locationId, dates) {
      let criteria = {
        Dates: dates
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/OpeningHour/location/${locationId}/dates`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
