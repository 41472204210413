<template>
  <div
    class="wrapper-placeholder"
    :class="{'is-loading' : isLoading }"
  >
    <span
      v-for="i in numberOfItems"
      :key="'item_' + i"
      class="placeholder has-background-grey-light"
      :class="{'has-spacing': hasSpacing}"
      :style="`width: ${getRandomInt(24,200)}px`"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'text-placeholder',
  props: {
    fixSize: {
      type: Number,
      default: 0
    },
    numberOfItems: {
      type: Number,
      default: 10
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasSpacing: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    /**
 * Get a random integer between `min` and `max`.
 * 
 * @param {number} min - min number
 * @param {number} max - max number
 * @return {number} a random integer
 */
    getRandomInt(min, max) {
      if (!this.fixSize) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      } else {
        return this.fixSize
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template";
$lineHeight: 6px;
.wrapper-placeholder {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: -$lineHeight;
  margin-bottom: -$lineHeight;
  .placeholder {
    height: 14px;
    border-radius: $radius;
    &.has-spacing {
      margin: $lineHeight 10px $lineHeight 0;
    }
  }

  &.is-loading {
    animation: placeHolderShimmer 2s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  @keyframes placeHolderShimmer {
    0% {
      opacity: 0.4;
    }
    // 25% {
    //   opacity: 0.35;
    // }
    50% {
      opacity: 1;
    }
    // 75% {
    //   opacity: 0.35;
    // }
    100% {
      opacity: 0.4;
    }
  }
}
</style>