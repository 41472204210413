<template>
  <div>
    <modal-edit-exception-opening-hours
      v-if="showEditModal"
      :showModal="showEditModal"
      :onClickCancel="
        () => {
          showHideEditModal(false)
        }
      "
      :selectedMeetingtypeId="selectedMeetingtypeId"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="
        () => {
          showHideEditModal(true)
        }
      "
    >
      <template v-slot:title>Exceptions</template>
    </ui-section-header>

    <month-year-selector
      class="has-margin-bottom"
      @monthYearSelected="monthYearSelected"
      :isLoadingLeft="isLoading"
      :isLoadingRight="isLoading"
      :month="month"
      :year="year"
      :showMonthSelector="false"
    ></month-year-selector>

    <table class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Day</th>
          <th>Date</th>
          <th>Time</th>
          <th>Open</th>
        </tr>
      </thead>
      <transition name="fade" mode="out-in">
        <tbody v-if="isLoading" key="loading">
          <tr v-for="i in 30" :key="'item_' + i">
            <td>
              <text-placeholder
                class="is-inline-flex"
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="120"
                :numberOfItems="1"
              />
            </td>
            <td>
              <text-placeholder
                class="is-inline-flex"
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="140"
                :numberOfItems="1"
              />
            </td>
            <td>
              <text-placeholder
                class="is-inline-flex"
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="60"
                :numberOfItems="1"
              />
              <span
                class="span-minus is-inline-flex has-padding-left has-padding-right"
                >-</span
              >
              <text-placeholder
                class="is-inline-flex"
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="60"
                :numberOfItems="1"
              />
            </td>
            <td>
              <text-placeholder
                class="is-inline-flex"
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="80"
                :numberOfItems="1"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else key="ready">
          <tr
            v-for="(openingHour, index) in exceptionOpeningHours"
            :key="'do_' + index"
          >
            <td>
              {{ (new Date(openingHour.Date).getDay() + 1) | getDayName }}
            </td>
            <td>
              {{
                openingHour.Date
                  | parseIsoDateStringToDate
                  | dateObjectIsoDateString
              }}
            </td>
            <td>
              {{ openingHour.OpenMinutes | minutesToTime }}
              <span class="has-padding-left has-padding-right">-</span>
              {{ openingHour.CloseMinutes | minutesToTime }}
            </td>
            <td>{{ openingHour.Open ? 'Is open' : 'Is closed' }}</td>
          </tr>
        </tbody>
      </transition>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import openingsHoursProvider from '@/providers/openinghours'
import TextPlaceholder from '@/components/UI/TextPlaceholder'
import MonthYearSelector from '@/components/UI/MonthYearSelector'

const ModalEditExceptionOpeningHours = () =>
  import('@/components/Locations/OpeningHours/ModalEditExceptionOpeningHours')

export default {
  name: 'exception-opening-hours',

  components: {
    'text-placeholder': TextPlaceholder,
    'month-year-selector': MonthYearSelector,
    'modal-edit-exception-opening-hours': ModalEditExceptionOpeningHours,
  },

  props: {
    selectedMeetingtypeId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      isLoading: false,
      placeHolderStyle: { marginTop: '0px' },
      showEditModal: false,
      lastDayOfMonth: 0,
    }
  },

  computed: {
    ...mapState('locationStore', [
      'location',
      'defaultOpeningHours',
      'exceptionOpeningHours',
    ]),
  },

  watch: {
    defaultOpeningHours: {
      deep: true,
      handler(val, oldVal) {
        this.getOpeningHours()
      },
    },
  },

  beforeDestroy() {
    this.setExceptionOpeningHours([])
  },

  methods: {
    ...mapMutations('locationStore', ['setExceptionOpeningHours']),

    /**
     * Get opening hours date settings
     */
    getOpeningHours() {
      let self = this

      this.calcLastDay()

      let startDate = self.$options.filters.dateObjectIsoDateString(
        this.year.toString() + '-' + this.month.toString() + '-1'
      )
      let endDate = self.$options.filters.dateObjectIsoDateString(
        this.year.toString() +
          '-' +
          this.month.toString() +
          '-' +
          this.lastDayOfMonth.toString()
      )

      if (!self.isLoading) {
        self.isLoading = true
        openingsHoursProvider.methods
          .getOpeningHoursDate(
            self.location.Id,
            self.selectedMeetingtypeId,
            startDate,
            endDate
          )
          .then((response) => {
            if (response.status === 200) {
              self.setExceptionOpeningHours(response.data)
            }
          })
          .catch((e) => {})
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    /**
     * Update month and year
     */
    monthYearSelected(data) {
      let self = this
      this.month = Number(data.month)
      this.year = Number(data.year)
      this.getOpeningHours()
    },

    /**
     * Show/Hide edit modal
     */
    showHideEditModal(val = false) {
      this.showEditModal = val
    },

    /**
     *calculates the last day of the selected month and assigns a number value (of either 28, 29, 30 or 31) to lastDayOfMonth
     */
    calcLastDay() {
      let lastDay = new Date(this.year, this.month, 0).getDate()
      this.lastDayOfMonth = lastDay
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  td {
    vertical-align: middle;
    width: 25%;
    .span-minus {
      line-height: 1;
    }
  }
}
</style>
