<template>
  <div
    class="card has-text-centered has-background-grey-light has-margin-bottom"
  >
    <div class="card-content">
      <span>
        <a
          @click="goToPrevious()"
          class="button is-small"
          :class="{
            'is-loading': isLoadingLeft,
            'is-success': !differencesDiscovered,
            'is-disabled': differencesDiscovered
          }"
        >
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
        </a>
      </span>
      <span v-if="showMonthSelector" class="select">
        <select @change="selectionChanged" v-model="mMonth">
          <option :value="0">All</option>
          <option :value="1">January</option>
          <option :value="2">February</option>
          <option :value="3">March</option>
          <option :value="4">April</option>
          <option :value="5">May</option>
          <option :value="6">June</option>
          <option :value="7">July</option>
          <option :value="8">August</option>
          <option :value="9">September</option>
          <option :value="10">October</option>
          <option :value="11">November</option>
          <option :value="12">December</option>
        </select>
      </span>
      <span v-if="!showMonthSelector && mMonth > 0">{{
        mMonth | getMonthName
      }}</span>
      <span v-if="!showMonthSelector && mMonth === 0">All</span>
      <span class="is-size-5 has-text-weight-bold">{{ mYear }}</span>
      <span>
        <a
          @click="goToNext()"
          class="button is-small"
          :class="{
            'is-loading': isLoadingRight,
            'is-success': !differencesDiscovered,
            'is-disabled': differencesDiscovered
          }"
        >
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </span>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isLoadingLeft: {
      default: false,
      type: Boolean
    },

    isLoadingRight: {
      default: false,
      type: Boolean
    },

    month: {
      default: new Date().getMonth() + 1,
      type: Number
    },

    year: {
      default: new Date().getFullYear(),
      type: Number
    },

    showMonthSelector: {
      default: true,
      type: Boolean
    }
  },

  data() {
    return {
      mMonth: Number(this.month),
      mYear: Number(this.year)
    };
  },

  computed: {
    ...mapGetters({
      differencesDiscovered: "getDifferencesDiscovered"
    })
  },

  methods: {
    goToNext() {
      let self = this;

      // self.mIsLoadingRight = true
      if (!this.differencesDiscovered) {
        let month = self.mMonth;
        let year = self.mYear;

        if (month > 0) {
          month = month + 1;
          if (month > 12) {
            month = 1;
            year = year + 1;
          }
        } else {
          year = year + 1;
        }

        self.mMonth = month;
        self.mYear = year;

        self.selectionChanged("right");
      }
    },

    goToPrevious() {
      let self = this;

      // self.mIsLoadingLeft = true
      if (!this.differencesDiscovered) {
        let month = self.mMonth;
        let year = self.mYear;

        if (month > 0) {
          month = month - 1;
          if (month < 1) {
            month = 12;
            year = year - 1;
          }
        } else {
          year = year - 1;
        }

        self.mMonth = month;
        self.mYear = year;

        self.selectionChanged("left");
      }
    },

    selectionChanged(direction = "") {
      let self = this;

      if (!this.differencesDiscovered) {
        self.$emit("monthYearSelected", {
          direction: direction,
          month: self.mMonth,
          year: self.mYear
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";
div.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  span {
    margin-left: 5px;
    margin-right: 5px;
  }
}
</style>
